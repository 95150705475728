export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const REMOVE_CURRENT_USER = 'REMOVE_CURRENT_USER';
export const ADD_ERROR = 'ADD_ERROR';
export const REMOVE_ERROR = 'REMOVE_ERROR';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const SEND_TWO_FACTOR_CODE = 'SEND_TWO_FACTOR_CODE';
export const CONFIRM_TWO_FACTOR_CODE = 'CONFIRM_TWO_FACTOR_CODE';
export const TOGGLE_MODAL = 'TOGGLE_MODAL';
export const GET_USERS = 'GET_USERS';
export const GET_POSTS = 'GET_POSTS';
export const UPDATE_POST = 'UPDATE_POST';
export const DELETE_POST = 'DELETE_POST';
export const ADD_POST = 'ADD_POST';
